import React from 'react';
import useIntl from 'react-intl/lib/src/components/useIntl';
import Flex from '../../../global/Flex';
import useStyles from './styles';
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import Button from '@material-ui/core/Button';
import FormInput from '../../../global/FormInput';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import Link from '@material-ui/core/Link';
import FormControl from '@material-ui/core/FormControl';
import { ErrorMessage } from '@hookform/error-message';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { ReactComponent as VisibilityOn } from '../../../../../assets/icons/visibilityOn.svg';
import { ReactComponent as VisibilityOff } from '../../../../../assets/icons/visibilityOff.svg';

import { getControllFildsValue } from '../../../../../services/getControllFildsValue';
import { useSelector } from 'react-redux';

const ThirdStep = ({ setCurrentTab, formData, onSubmit }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const contentsList = useSelector((state) => state.contents.fields);

  const validationSchema = Yup.object().shape({
    position: Yup.string().required(
      formatMessage({ id: 'inputErrors_required' }),
    ),
    email: Yup.string()
      .email(formatMessage({ id: 'inputErrors_required_emil' }))
      .required(formatMessage({ id: 'inputErrors_required' })),
    password: Yup.string()
      .min(8, formatMessage({ id: 'inputErrors_required_password' }))
      .required(formatMessage({ id: 'inputErrors_required' })),
    checkbox: Yup.boolean()
      .required(formatMessage({ id: 'inputErrors_required' }))
      .isTrue(formatMessage({ id: 'inputErrors_required' })),
  });

  const { handleSubmit, control, errors } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: formData,
  });

  /*Password*/
  const [visiblePassword, setVisiblePassword] = React.useState(false);
  const handleClickShowPassword = () => {
    setVisiblePassword((visiblePassword) => !visiblePassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <FormControl>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <Flex spacing={20} container marginBottom column>
          <Flex xs='100%'>
            <FormInput
              error={errors.position}
              errors={errors}
              name='position'
              placeholder={formatMessage({ id: 'form_post' })}
              control={control}
              fullWidth
            />
          </Flex>
          <Flex xs='100%'>
            <FormInput
              error={errors.email}
              errors={errors}
              name='email'
              placeholder={formatMessage({ id: 'form_email' })}
              control={control}
              fullWidth
            />
          </Flex>
          <Flex xs='100%'>
            <FormInput
              type={visiblePassword ? 'text' : 'password'}
              error={errors.password}
              errors={errors}
              name='password'
              placeholder={formatMessage({ id: 'form_password' })}
              control={control}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {visiblePassword ? (
                        <VisibilityOn className={classes.visibility} />
                      ) : (
                        <VisibilityOff className={classes.visibility} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Flex>
          <Flex xs='100%'>
            <label className={classes.checkboxLabel}>
              <Controller
                render={(props) => {
                  return (
                    <Checkbox
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  );
                }}
                name='checkbox'
                control={control}
                id='checkbox'
              />
              {formatMessage({ id: 'form_checkbox_text' })}
              &nbsp;
              <Link
                href={getControllFildsValue(
                  contentsList,
                  'polit-link-ua',
                  'polit-link-en',
                )}
                target='_blank'
                className={classes.checkboxLink}
              >
                {formatMessage({ id: 'form_checkbox_privacy_policy' })}
              </Link>
            </label>
            <ErrorMessage
              render={({ message }) => (
                <div style={{ color: 'red', marginTop: 3 }}>{message}</div>
              )}
              errors={errors}
              name='checkbox'
            />
          </Flex>
          <Flex container>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              style={{
                width: '100%',
                height: '50px',
              }}
            >
              {formatMessage({ id: 'sign_up_form_button_finish' })}
            </Button>
          </Flex>
        </Flex>
        <Flex container>
          <Button
            variant='text'
            color='primary'
            onClick={() => {
              setCurrentTab(1);
            }}
            style={{
              display: 'block',
              margin: '0 auto',
            }}
          >
            {formatMessage({ id: 'form_button_back' })}
          </Button>
        </Flex>
      </form>
    </FormControl>
  );
};

export default ThirdStep;
