import { makeStyles } from '@material-ui/styles';

import palette from '../theme/palette';

const useStyles = makeStyles(() => ({
  layout: {
    position: 'relative',
    minHeight: '100vh',
    margin: '0',
    
  },
  main : {
    flexGrow: '1',
  },
  mainNotAuth:{
    backgroundColor: palette.common.sky,
  },
  header: {
    position: 'sticky',
    top: 0,
    left: 0,
    width: '100%',
    fontSize: '16px',
    lineHeight: '24px',
    backgroundColor: palette.common.sky,
    zIndex: '2',
  },
  headerInner: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '80px',
   

    '@media (max-width: 1000px)': {
      width:700,
      padding: '0 !important',
    },  
    '@media (max-width: 1440px)': {
      maxWidth: 1100,
      
    },
    '@media (max-width: 768px)': {
      width: '96%',
      height: '70px',
      padding: '0 !important',
    },
  },
  headerLeft: {
    display: 'flex',
    alignItems: 'center',

    '@media (max-width: 1000px)': {
      display: 'none',
    },
  },
  headerRight: {
    display: 'flex',
    alignItems: 'center',
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '50px',
    color: palette.primary.main,
    fontSize: '16px',
    lineHeight: '24px',
    cursor: 'pointer',
    "&:hover":{
      color:palette.secondary.main
    },

    '@media (max-width: 1440px)': {
      marginRight: '30px',
    },
    '@media (max-width: 1000px)': {
      display: 'flex',
      alignItems: 'center',
      marginRight: '0 !important',
      marginLeft: '0px',
      padding: '8px 20px',
      color: palette.primary.main,
      fontSize: '16px',
      lineHeight: '24px',
      cursor: 'pointer',
    },
  },
  menuSubItem: {
    width: '100%',
    padding: '5px 10px',
    "&:hover":{
      color:palette.secondary.main
    },
  },
  menuItemMobileActive: {
    color: palette.secondary.main,
    background: palette.common.sky,
  },
  menuItemLast: {
    marginRight: '0',
  },
  chevron: {
    width: '16px',
    height: '16px',
    marginLeft: '5px',
    transition: 'all 0.3s',
  },
  chevronFirst: {
    transform: (props) =>
      `rotate(${props.isMenuFirstOpen ? '180deg' : '0deg'})`,
  },
  chevronSecond: {
    transform: (props) =>
      `rotate(${props.isMenuSecondOpen ? '180deg' : '0deg'})`,
  },
  chevronThird: {
    transform: (props) =>
      `rotate(${props.isMenuThirdOpen ? '180deg' : '0deg'})`,
  },
  profileLink: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '70px',
    cursor: 'pointer',

    '@media (max-width: 1440px)': {
      marginLeft: '30px',
    },
  },
  personalAreaAvatar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '35px',
    height: '35px',
    marginRight: '10px',
    borderRadius: '50%',
    backgroundColor: palette.secondary.main,
  },
  personalAreaAvatarInner: {
    display: 'flex',
    padding: '5px 10px',
  },
  subItemPersonalArea: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    "&:hover":{
      color:palette.secondary.main
    }
  },
  fileTextIcon: {
    marginRight: '10px',
  },
  footer: {
    flexShrink: 0,

    /*position: (props)=>props.isHome?"fixed":'absolute',
    left: 0,
    bottom: (props)=>props.isHome?0:-231,*/

    width: '100%',
    height: '188px',
    padding: '25px 0',
    lineHeight: '20px',
    fontWeight: '200',
    backgroundColor: palette.common.sky,
    borderTop: '1px solid rgba(164, 183, 207, 0.3)',
    zIndex: '2',

    '@media (max-width: 1000px)': {
      position: 'inherit',
      // height: 'auto',
      padding: '15px 0',
    },
    "@media (max-width:575px)":{
      height:310
    }
  },
  footerInner: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',

    '@media (max-width: 1000px)': {
      maxWidth: '100%',
    },
    '@media (max-width: 768px)': {
      maxWidth: '96%',
      padding: '0 !important',
    },
  },
  footerColumn: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '295px',
    '&:last-child': {
      flexDirection: 'row',
    },

    '@media (max-width: 1000px)': {
      maxWidth: '50%',
      '&:last-child': {
        justifyContent: 'space-between',
        maxWidth: '100%',
        width: '100%',
      },
    },
    '@media (max-width: 768px)': {
      maxWidth: '100%',
    },
  },
  footerTitle: {
    height: '40px',
    maxWidth: '260px',
    marginBottom: '20px',
  },
  footerItem: {
    display: 'flex',
    alignItems: 'center',
    height: '40px',
    '&:first-child': {
      marginBottom: '15px',
    },
    '& svg': {
      width: '20px',
      height: '20px',
      marginRight: '10px',
    },

    '@media (max-width: 1000px)': {
      marginRight: '25px',
      '&:last-child': {
        marginRight: '0',
      },
    },
    '@media (max-width: 768px)': {
      height: 'auto',
      marginBottom: '15px',
    },
  },
  footerItemHidden: {
    '@media (max-width: 768px)': {
      marginBottom: '0 !important',
    },
  },
  footerItemBottom: {
    '@media (max-width: 1000px)': {
      marginBottom: '0 !important',
    },
    '@media (max-width: 768px)': {
      marginBottom: '15px !important',
    },
  },
  footerItemFacebook: {
    width: '180px',
    '& svg': {
      marginRight: '0',
      marginLeft: '8px',
    },

    '@media (max-width: 768px)': {
      marginBottom: '0 !important',
    },
  },
  footerPhoneLink: {
    display: 'block',
  },
  footerInfo: {
    '@media (max-width: 1000px)': {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    '@media (max-width: 768px)': {
      flexDirection: 'column',
    },
  },
  footerSwitcher: {
    display: 'flex',
    alignItems: 'flex-end',

    '@media (max-width: 1000px)': {
      alignItems: 'flex-start',
      marginLeft: '25px',
    },
    '@media (max-width: 768px)': {
      alignItems: 'flex-end',
    },
  },
  footerItemSwitcher: {
    marginBottom: '0 !important',
  },

  mobileMenuToggle: {
    width: '32px',
    height: '32px',
  },
  mobileMenuClose: {
    display: 'flex',
    justifyContent: 'flex-end',
    color: palette.primary.main,
    marginRight: '15px',
    "&>svg:hover":{
      color: palette.secondary.main,
      cursor:'pointer'
    }
  },
  mobileMenu: {
    marginTop: '25px',
  },
}));

export default useStyles;
